import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";



import TagManager from "react-gtm-module";
import { clarity } from "react-microsoft-clarity";
const tagManagerArgs = {
  gtmId: "GTM-TN2T5XVS",
};
TagManager.initialize(tagManagerArgs);
if (process.env.NODE_ENV === "production") clarity.init("ngguuyptlu");
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
       <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
