import theme from "./index";

export default {
  hb1: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "52px",
    fontWeight: 500,
    textAlign: "left",
  },
  hb2: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "42px",
    fontWeight: 600,
    textAlign: "left",
  },
  hb3: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "40px",
    fontWeight: 500,
    textAlign: "left",
  },
  // hb4: {
  //   fontFamily: "Poppins, sans-serif",
  //   fontSize: "46px",
  //   fontWeight: 800,
  //   textAlign: "center",
  //   color: "#F24535",
  // },
  hb4: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "33px",
    fontWeight: 800,
    // color: "#F24535",
  },
  h2: {
    fontWeight: 500,
    fontSize: 32,
    fontFamily: "Poppins, sans-serif",
    lineHeight: "1.4",
  },
  h3: {
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: 1.5,
    fontFamily: "Poppins, sans-serif",
  },
  h4: {
    fontWeight: 400,
    fontSize: "24px",
    fontFamily: "Poppins, sans-serif",
    textAlign: "center",
  },

  h5: {
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: 1.5,
    fontFamily: "Poppins, sans-serif",
  },
  h6: {
    fontFamily: "Plus Jakarta Sans, sans-serif",
    fontSize: "19px",
    fontWeight: 700,
    // lineHeight: "20px",
    // letterSpacing: "0.1em",
    textAlign: "left",
  },
  h7: {
    fontSize: 18,
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
  },
  overline: {
    fontWeight: 500,
    fontFamily: "Poppins, sans-serif",
  },
  footer: {
    fontWeight: 400,
    fontSize: 24,
    fontFamily: "Roboto, Poppins, sans-serif",
  },
  // button: {
  //   fontSize: 15,
  //   fontFamily: "Poppins, sans-serif",
  // },
};
