import React, { memo } from "react";
import {
  Grid,
  Container,
  Box,
  Typography,
  IconButton,
  Button,
  Link,
} from "@mui/material";
import PlayStore from "../img/google-play.png";
import { ReactComponent as Facebook } from "../img/icon/icons8-facebook.svg";
import { ReactComponent as YouTube } from "../img/icon/icons8-youtube.svg";
import { ReactComponent as Twitter } from "../img/icon/icons8-twitterx.svg";
import { ReactComponent as Instagram } from "../img/icon/icons8-instagram.svg";
import { ReactComponent as LinkedIn } from "../img/icon/icons8-linkedin.svg";

const style = {
  heading: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "left",
    color: "rgba(255,255,255, 0.8)",
    marginBottom: "5px",
  },
  subHeading: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: "14.08px",
    textAlign: "left",
    color: "rgba(255,255,255, 0.8)",
  },
};

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#1D1D1D" }}>
      {/* <Container maxWidth={"lg"} sx={{ position: "relative", py: 2 }}>
        <div
          style={{
            filter: "brightness(0) invert(1)",
          }}
        >
          <img src={Logo} height={70} />
        </div>
      </Container>
      <Divider
        sx={{
          borderColor: "#4a4a4a",
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      /> */}

      <Container maxWidth={"lg"} sx={{ position: "relative" }}>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} lg={3} md={4} sm={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                href="https://www.linkedin.com/company/v-marc/"
                aria-label="LinkedIn"
                size="small"
                sx={{
                  transition: "transform 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    transform: "scale(1.2)", // Increase size on hover
                  },
                }}
                // sx={{
                //   color: "rgba(255,255,255, 0.8)",
                //   mr: 0,
                //   p: 0,
                //   "&:hover": {
                //     color: theme.palette.primary.main,
                //   },
                // }}
              >
                <LinkedIn sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://www.instagram.com/vmarcindia?igshid=NDk5N2NlZjQ%3D"
                size="small"
                sx={{
                  transition: "transform 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    transform: "scale(1.2)", // Increase size on hover
                  },
                }}
                // aria-label="Instagram"
                // sx={{
                //   color: "rgba(255,255,255, 0.8)",
                //   mr: 0,
                //   "&:hover": {
                //     color: theme.palette.primary.main,
                //   },
                // }}
              >
                <Instagram sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://twitter.com/VMARCwires"
                size="small"
                sx={{
                  transition: "transform 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    transform: "scale(1.2)", // Increase size on hover
                  },
                }}
                // aria-label="Twitter"
                // sx={{
                //   color: "rgba(255,255,255, 0.8)",
                //   mr: 0,
                //   "&:hover": {
                //     color: theme.palette.primary.main,
                //   },
                // }}
              >
                <Twitter sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://www.youtube.com/@v-marcwirescables8469/videos"
                size="small"
                sx={{
                  transition: "transform 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    transform: "scale(1.2)", // Increase size on hover
                  },
                }}
                // aria-label="YouTube"
                // sx={{
                //   color: "rgba(255,255,255, 0.8)",
                //   mr: 0,
                //   "&:hover": {
                //     color: theme.palette.primary.main,
                //   },
                // }}
              >
                <YouTube sx={{ fontSize: 20 }} />
              </IconButton>
              <IconButton
                href="https://www.facebook.com/profile.php?id=100068702384250"
                size="small"
                sx={{
                  transition: "transform 0.3s ease-in-out", // Smooth transition
                  "&:hover": {
                    transform: "scale(1.2)", // Increase size on hover
                  },
                }}
                // aria-label="Facebook"
                // sx={{
                //   color: "rgba(255,255,255, 0.8)",
                //   mr: 0,
                //   "&:hover": {
                //     color: theme.palette.primary.main,
                //   },
                // }}
              >
                <Facebook />
              </IconButton>
            </Box>

            <Typography style={style.subHeading} mt={2} mb={1}>
              Download V-Marc Utsav App
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                py: 2,
              }}
            >
              <Button
                href="https://play.google.com/store/apps/details?id=com.unotag.vmarc"
                sx={{ p: 0 }}
              >
                <img
                  src={PlayStore}
                  alt="Play Store"
                  width={120}
                  style={{ marginRight: "5px" }}
                />
              </Button>
              {/* <img src={AppStore} width={120} /> */}
            </Box>
            {/* <Typography variant="h5" color={"#A9A27B"}>
          {position}
        </Typography> */}
          </Grid>

          <Grid item xs={12} lg={3} md={4} sm={6} sx={{}}>
            <Box>
              <Typography style={style.heading} color="white">
                Registered Office
              </Typography>
              <Typography style={{ ...style.subHeading, marginBottom: "7px" }}>
                Plot No. 3, 4, 18 & 20 A, Sector – IIDC, SIDCUL, Haridwar,
                Uttarakhand – 249403
              </Typography>
              <Typography style={style.subHeading}>
                <b>Toll Free Number: </b>
                <Link href="tel:+91 1800 120 3618" color="inherit">
                  1800 120 3618
                </Link>
                <br />
                <b>Phone:</b>
                <Link href="tel:01334-239638" color="inherit">
                  01334-239638
                </Link>
                <br />
                <b>Customer Support:</b>{" "}
                <Link href="tel:70555 21808 " color="inherit">
                  70555 21808
                </Link>
                <br />
                <b>Email:</b>
                <Link href="mailto: info@v-marc.in" color="inherit">
                  info@v-marc.in
                </Link>{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} lg={3} md={4} sm={6} sx={{}}>
            <Box>
              <Typography style={style.heading} color="white">
                Noida Office
              </Typography>
              <Typography style={{ ...style.subHeading, marginBottom: "7px" }}>
                Trapezoid IT Park, 3rd Floor, C-27, C Block,Phase 2, Sector-62,
                Noida, Uttar Pradesh - 201309
              </Typography>
              <Typography style={style.subHeading}>
                <b>Email:</b>{" "}
                <Link href="mailto:sales@v-marc.in" color="inherit">
                  sales@v-marc.in
                </Link>
                <br />
                <Link href="mailto:salesproject@v-marc.in" color="inherit">
                  salesproject@v-marc.in
                </Link>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3} md={4} sm={6} sx={{}}>
            <Box>
              <Typography style={style.heading} color="white">
                International Business
              </Typography>
              <Typography style={{ ...style.subHeading, marginBottom: "7px" }}>
                For export enquiries contact:
              </Typography>
              <Typography style={style.subHeading}>
                <b>Email:</b>{" "}
                <Link href="mailto:exports@v-marc.in" color="inherit">
                  exports@v-marc.in
                </Link>
                <br />
                {/* <Link href="mailto:salesproject@v-marc.in" color="inherit">
                  salesproject@v-marc.in
                </Link> */}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            <Typography
              style={style.subHeading}
              sx={{ alignSelf: "flex-end", textAlign: "right" }}
            >
              ©V-MARC India Limited. All Rights Reserved.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default memo(Footer);
