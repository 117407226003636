import React, { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme";
import { Button, MenuItem } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const styles = {
  header: {
    fontFamily: "Poppins",
    fontSize: "21.41px",
    fontWeight: 600,
    color: "#fff",
  },
  subHead: {
    fontFamily: "Poppins",
    fontSize: "10.7px",
    fontWeight: 500,
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  p: {
    fontFamily: "Poppins",
    fontSize: "12.23px",
    fontWeight: 400,
    color: "#ffff",
  },
};
const Dropdown = ({ children, title, Path, right }) => {
  const [aboutusMenu, setAboutusMenu] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const lcaseN = location.pathname.toLowerCase().trim();

  function handleOpen(event) {
    if (aboutusMenu !== event.currentTarget) {
      setAboutusMenu(event.currentTarget);
    }
  }

  function handleClose() {
    setAboutusMenu(null);
  }
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
      onMouseLeave={handleClose}
    >
      {right ? (
        <MenuItem
          sx={{
            width: "100%",
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
          }}
          onClick={handleOpen}
          onMouseOver={handleOpen}
        >
          {title}
        </MenuItem>
      ) : (
        <Button
          aria-owns={aboutusMenu ? "about-menu" : undefined}
          aria-haspopup="true"
          onClick={() => navigate(Path)}
          onMouseOver={handleOpen}
          sx={{
            color: `${theme.palette.textPrimary.main}`,
            display: "block",
            textTransform: "capitalize",
            "&:hover": {
              color: `${theme.palette.primary.main}`,
              backgroundColor: "transparent",
            },
            position: "relative",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: 1.5,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          {title}
          <ExpandMore
            sx={{
              marginLeft: "5px",
              verticalAlign: "middle",
            }}
          />
          <span
            style={{
              position: "absolute",
              left: 0,
              bottom: 0, // Adjust this value to control the gap between text and underline
              width: lcaseN.includes(Path) ? "100%" : "0",
              borderBottom: `2px solid ${theme.palette.primary.main}`,
            }}
          ></span>
        </Button>
      )}
      <div
        style={{
          display: !Boolean(aboutusMenu) ? "none" : "block",
          position: "absolute",
          top: right ? 0 : 70,
          left: right ? 190 : 0,
          backgroundColor: "#333", // Dark background color
          color: "#fff", // Light text color
          borderRadius: 5,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(Dropdown);
